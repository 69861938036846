import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from "react-helmet-async";
/* custom Hooks and stylesheet */
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import $ from 'jquery';
import Popper from 'popper.js'

// Components
const Landing = lazy(() => import("./pages/Landing"));
const SaifaireduLanding = lazy(() => import("./pages/Saifairedu"));
const EcoleSup = lazy(() => import("./pages/Saifairedu/Ecolesup"));
const Lycees = lazy(() => import("./pages/Saifairedu/Lycees"));
const Primaires = lazy(() => import("./pages/Saifairedu/Primaires"));
const Centres = lazy(() => import("./pages/Saifairedu/Centres"));
const Stand = lazy(() => import("./pages/Saifairedu/Stand"));
const Tuto1 = lazy(() => import("./pages/Saifairedu/tuto1"));
const StandAdmin = lazy(() => import("./components/stands/Droits"));
const StandMed= lazy(() => import("./components/stands/Sciences"));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Suspense fallback={<div>Loading</div>}>
        <HelmetProvider>
          <Routes>
            <Route path="/" index element={<Landing />} />
            <Route path="/saifairedu_et_campusinter" index element={<SaifaireduLanding />} />
            <Route path="/ecolesup" index element={<EcoleSup />} />
            <Route path="/lycees" index element={<Lycees />} />
            <Route path="/primaires" index element={<Primaires />} />
            <Route path="/centres" index element={<Centres />} />
            <Route path="/stand" index element={<Stand />} />
            <Route path="/saifairedututorial" index element={<Tuto1 />} />
            <Route path="/stand/admin" index element={<StandAdmin />} />
            <Route path="/stand/medecine" index element={<StandMed />} />
          </Routes>
        </HelmetProvider>
      </Suspense>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
